<template>
  <div class="content_body" v-loading="loading">
    <!-- 头部筛选 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="22">
          <el-form :inline="true" size="small" @submit.native.prevent>
            <el-form-item label="采购出库仓">
              <el-input v-model="procurement" placeholder="输入出仓库名称搜索" clearable @keyup.enter.native="handleSearch" @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="采购入库仓">
              <el-input v-model="warehousing" placeholder="输入入仓库名称搜索" clearable @keyup.enter.native="handleSearch" @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="单据号">
              <el-input v-model="OrderBillN" placeholder="输入订单编号搜索" clearable @keyup.enter.native="handleSearch" @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="制单日期">
              <!-- <el-input
                v-model="TimeArray"
                placeholder="输入订单编号搜索"
                clearable
                @keyup.enter.native="handleSearch"
                @clear="handleSearch"
              ></el-input> -->
              <el-date-picker v-model="TimeArray" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"> </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" v-prevent-click @click="downloadExcel" :loading="downloadExcel1">导出</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2" class="text_right">
          <el-button v-if="isAdd" type="primary" @click="addClick" size="small" v-prevent-click>新增</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容区域 -->
    <div class="martp_10">
      <el-tabs type="border-card" v-model="ActiveName" @tab-click="getList">
        <el-tab-pane label="全部" name="333"></el-tab-pane>
        <el-tab-pane label="待审核" name="10">
          <span slot="label"
            >待审核
            <el-badge v-if="StatusNumberInfo && StatusNumberInfo.BillStatus10 != 0" is-dot />
          </span>
        </el-tab-pane>
        <el-tab-pane label="待配送" name="20">
          <span slot="label"
            >待配送
            <el-badge v-if="StatusNumberInfo && StatusNumberInfo.BillStatus20 != 0" is-dot />
          </span>
        </el-tab-pane>
        <el-tab-pane label="待入库" name="30">
          <span slot="label"
            >待入库
            <el-badge v-if="StatusNumberInfo && StatusNumberInfo.BillStatus30 != 0" is-dot />
          </span>
        </el-tab-pane>
        <el-tab-pane label="已驳回" name="40">
          <span slot="label"
            >已驳回
            <el-badge v-if="StatusNumberInfo && StatusNumberInfo.BillStatus40 != 0" is-dot />
          </span>
        </el-tab-pane>
        <el-tab-pane label="已完成" name="50"></el-tab-pane>
        <el-tab-pane label="已关闭" name="60"></el-tab-pane>
      </el-tabs>
      <div class="martp_10">
        <el-table size="small" :data="tableData" tooltip-effect="light">
          <el-table-column prop="ID" label="单据号"></el-table-column>
          <el-table-column prop="OutboundEntityName" label="采购出库仓"></el-table-column>
          <el-table-column prop="InboundEntityName" label="采购入库仓"></el-table-column>
          <el-table-column prop="BillStatus" label="单据状态" :formatter="(row, column) => formatStatus(row.BillStatus)"></el-table-column>
          <el-table-column prop="TotalAmount" label="单据总额(元)">
            <template slot-scope="scope">
              <span v-if="isPriceShow">{{ scope.row.TotalAmount }}</span>
              <span v-else>***</span>
            </template>
          </el-table-column>
          <el-table-column prop="CreatedOn" label="制单时间"></el-table-column>
          <el-table-column prop="CreatedByName" label="申请人"></el-table-column>
          <el-table-column prop="Remark" label="申请备注信息" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" width="240" align="right">
            <template slot-scope="scope">
              <el-button type="danger" size="small" v-if="(scope.row.BillStatus == '10' && isClose) || (scope.row.BillStatus == '40' && isTurnClose)" @click="submitCancle(scope.row)">关闭</el-button>
              <el-button type="primary" size="small" v-if="scope.row.BillStatus == '30' && scope.row.InboundEntityPermission && isStorage" @click="Approve(scope.row, 'PutinDialog')" v-prevent-click>入库</el-button>
              <el-button type="primary" size="small" v-if="scope.row.BillStatus == '10' && scope.row.OutboundEntityPermission && isCheck" @click="Approve(scope.row, 'ExamineDialog')" v-prevent-click>审批</el-button>
              <el-button type="primary" size="small" v-if="scope.row.BillStatus == '20' && scope.row.OutboundEntityPermission && isDelivery" @click="Approve(scope.row, 'PatchDialog')" v-prevent-click>配送</el-button>
              <el-button type="primary" size="small" @click="Approve(scope.row, 'DetailDialog')" v-prevent-click>详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pad_15 text_right">
        <el-pagination background v-if="paginations.total > 0" @current-change="CurrenthandleSearch" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>
    <!-- 新增弹窗 -->
    <el-dialog title="新建申请" :visible.sync="Dialog" width="1200px" @close="CloseDialog">
      <div style="height: 60vh">
        <el-scrollbar class="el-scrollbar_height">
          <div class="tip marbm_10" style="margin-top: 0">采购信息</div>
          <el-form ref="purchase" :model="ruleForm" :rules="purchaseRules" label-width="130px" size="small">
            <el-row class="dis_flex flex_wrap">
              <el-col :span="6">
                <el-form-item label="仓库/门店" prop="OutboundEntityID">
                  <el-select filterable :disabled="ruleForm.InboundEntityID == ''" v-model="ruleForm.OutboundEntityID" placeholder="请选择仓库/门店" @change="OutboundEntityChange">
                    <el-option v-for="item in warehouseList" :key="item.ID" :label="item.EntityName" :value="item.ID"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="收货仓" prop="InboundEntityID">
                  <el-select v-model="ruleForm.InboundEntityID" filterable placeholder="请选择仓库/门店" @change="warehouseEntity">
                    <el-option v-for="item in entityList" :key="item.ID" :label="item.EntityName" :value="item.ID"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="Remark" label="备注信息">
                  <el-input resize="none" type="textarea" :rows="1" placeholder="备注信息" v-model="ruleForm.Remark"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <div slot="label" class="dis_flex flex_wrap">
                    <span class="font_13 color_666 width__fill_available">图片上传:</span>
                    <span class="font_13 color_999 width__fill_available" style="margin-top: -10px">上传支付凭证</span>
                  </div>
                  <el-upload
                    class="dis_flex"
                    action="#"
                    list-type="picture-card"
                    :before-upload="
                      (file) => {
                        return beforeAvatarUpload(file, 'creat');
                      }
                    "
                    :file-list="ruleForm.imageList"
                  >
                    <!-- :file-list="fileList" -->
                    <i slot="default" class="el-icon-camera-solid" style="font-size: 40px; color: #999"></i>
                    <div style="width: 80px; height: 80px" slot="file" slot-scope="{ file }">
                      <el-image class="el-upload-list__item-thumbnail" style="width: 80px; height: 80px" :src="file.url" :id="file.uid" :preview-src-list="ruleForm.images" :z-index="9999" />
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="tip marbm_10" style="margin-top: 0">产品明细</div>
          <el-row>
            <el-col :span="17">
              <el-button type="primary" size="small" @click="addProducts('Product')">添加产品</el-button>
              <el-button type="primary" size="small" @click="addProducts('Package')">添加套餐</el-button>
              <el-button type="danger" size="small" @click="removeMultipleProduct" :disabled="removeDisabled">删除产品</el-button>
            </el-col>
            <el-col v-if="isPriceShow" :span="6" class="text_right"> 采购金额：{{ ruleForm.TotalAmount | NumFormat }} </el-col>
            <el-col v-else :span="6" class="text_right"> 采购金额：***</el-col>
          </el-row>
          <el-table empty-text="暂无产品" size="small" class="martp_10" max-height="280px" :data="ruleForm.Detail" @selection-change="removeHandleChangeSelectProduct">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column type="expand">
              <div slot-scope="scope" v-if="scope.row.type == 'Package'">
                <el-row class="expand-class" style="padding-left: 112px" v-for="item in scope.row.ProductList" :key="item.ID">
                  <el-col :span="4"
                    >{{ item.Name }}
                    <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                  </el-col>
                  <el-col :span="3">{{ item.Specification || "-" }}</el-col>
                  <el-col :span="3">{{ item.StockQuantity }}</el-col>
                  <el-col :span="3">{{ item.UnitName }}</el-col>
                  <el-col v-if="isPriceShow" :span="3"> {{ item.Price }}</el-col>
                  <el-col v-else :span="3">***</el-col>
                  <el-col :span="3">{{ item.Amount }}</el-col>
                  <el-col :span="2">{{ item.MinimumUnitAmount + item.MinimumUnitName }}</el-col>
                  <el-col v-if="isPriceShow" :span="3">{{ item.TotalPrice }}</el-col>
                  <el-col v-else :span="3">***</el-col>
                </el-row>
              </div>
            </el-table-column>
            <el-table-column label="产品名称/套餐名称" prop="ProductName" width="180">
              <div slot-scope="scope" class="dis_flex flex_y_center">
                <el-select
                  v-if="scope.row.type == 'Product'"
                  v-model="scope.row.ID"
                  size="small"
                  filterable
                  clearable
                  remote
                  reserve-keyword
                  v-loadmore="loadMoreProduct"
                  placeholder="请选择产品"
                  :remote-method="searchProductListMethod"
                  @change="(val) => handleSelectProduct(val, scope.row)"
                  @focus="searchProductListMethod('')"
                >
                  <el-option v-for="item in ProductList" :key="item.ID" :label="item.ProductName" :value="item.ID" :disabled="item.IsLock"> </el-option>
                </el-select>
                <el-select
                  v-else
                  v-model="scope.row.ID"
                  size="small"
                  placeholder="选择套餐卡"
                  filterable
                  clearable
                  remote
                  reserve-keyword
                  v-loadmore="loadMorePackage"
                  :remote-method="searchPackageListMethod"
                  @change="(val) => handleSelectPackage(val, scope.row)"
                  @focus="searchPackageListMethod('')"
                >
                  <el-option v-for="item in Packagecard" :key="item.ID" :label="item.Name" :value="item.ID" :disabled="item.IsLock"> </el-option>
                </el-select>
                <!-- <el-button type="text" size="small" class="marlt_10"
                  >展开</el-button
                > -->
              </div>
            </el-table-column>
            <el-table-column prop="Specification" label="产品规格"></el-table-column>
            <el-table-column prop="Quantity" label="可用库存"></el-table-column>
            <el-table-column prop="Specification" label="采购单位">
              <template slot-scope="scope">
                <el-select v-if="scope.row.type == 'Product'" v-model="scope.row.UnitID" @change="(val) => handleSelectUnit(val, scope.row)" size="small" filterable placeholder="选择单位">
                  <el-option v-for="item in scope.row.Units" :key="item.UnitID" :label="item.UnitName" :value="item.UnitID"> </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="Price" label="最小包装采购价">
              <template slot-scope="scope">
                <span v-if="isPriceShow">{{ scope.row.Price }}</span>
                <span v-else>***</span>
              </template>
            </el-table-column>
            <el-table-column prop="PackagePrice" label="采购单位数量">
              <template slot-scope="{ row }">
                <el-input size="small" v-model="row.Amount" @input="computeProducts" @change="amountChange(row)" v-enter-number2 type="number"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="最小包装数量">
              <template slot-scope="{ row }" v-if="row.type == 'Product'">
                {{ row.Amount * row.UnitAmount + row.MinimumUnitName || "" }}
              </template>
            </el-table-column>
            <!-- <el-table-column prop="Amount" label="数量">
                   <template slot-scope="{row}">
                    <el-input size="small" v-model="row.Amount" type="number" min="0" v-enter-int></el-input>
                  </template>
                </el-table-column> -->
            <el-table-column label="合计金额">
              <template slot-scope="{ row }">
                <div v-if="isPriceShow">
                  <div v-if="row.type == 'Product'">
                    {{ row.Price * row.Amount * row.UnitAmount || 0 }}
                  </div>
                  <div v-else>
                    {{ row.Price * row.Amount || 0 }}
                  </div>
                </div>
                <div v-else>***</div>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
      <div slot="footer">
        <el-button size="small" @click="Dialog = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" :loading="modalLoading" @click="submitAdd('purchase')" v-prevent-click>提交申请 </el-button>
      </div>
    </el-dialog>
    <!-- 审批弹窗 -->
    <el-dialog title="采购审批" :visible.sync="ExamineDialog" width="1200px" @close="CloseDialog">
      <div style="height: 60vh">
        <el-scrollbar class="el-scrollbar_height">
          <div class="tip marbm_10" style="margin-top: 0">基本信息</div>
          <el-form ref="purchase" :model="ruleForm" label-width="130px" size="small">
            <el-row class="dis_flex flex_wrap">
              <el-col :span="8">
                <el-form-item label="采购出库仓:" prop="warehouseName">{{ ruleForm.OutboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="采购状态:" prop="receiving">{{ formatStatus(ruleForm.BillStatus) }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="CreatedByName" label="申请人:">{{ ruleForm.CreatedByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="采购入库仓:" prop="InboundEntityName">{{ ruleForm.InboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="采购总额:" prop="receiving">{{ ruleForm.TotalAmount | NumFormat }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="CreatedOn" label="申请时间:">{{ ruleForm.CreatedOn }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="Remark" label="申请备注信息:">{{ ruleForm.Remark }}</el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="图片查看:">
                  <div class="dis_flex flex_wrap">
                    <el-image v-for="item in ruleForm.ApplyImage" :key="item" :src="item" :preview-src-list="ruleForm.ApplyImage" class="image"></el-image>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="tip marbm_10" style="margin-top: 0">产品明细</div>
          <el-table empty-text="暂无产品" size="small" class="martp_10" max-height="280px" :data="ruleForm.Detail">
            <el-table-column type="expand">
              <div slot-scope="scope" v-if="scope.row.type == 'Package'">
                <el-row class="expand-class" v-for="item in scope.row.ProductList" :key="item.ID">
                  <el-col :span="4"
                    >{{ item.Name }}
                    <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                  </el-col>
                  <el-col :span="5">{{ item.StockQuantity }}</el-col>
                  <el-col :span="5">{{ item.Quantity }}</el-col>
                  <el-col :span="5">{{ item.Price }}</el-col>
                  <el-col :span="5">{{ item.TotalPrice }}</el-col>
                </el-row>
              </div>
            </el-table-column>
            <el-table-column label="产品名称/套餐名称" prop="Name" width="180">
              <template slot-scope="{ row }">
                {{ row.Name }} <el-tag size="mini" class="marlt_10">{{ row.type == "Package" ? "套餐" : "产品" }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="StockQuantity" label="实物库存"></el-table-column>
            <el-table-column prop="Quantity" label="采购数量"></el-table-column>
            <el-table-column prop="Price" label="采购价">
              <template slot-scope="scope">
                <el-input v-model="scope.row.Price" size="small" @change="ExamineInput(scope.row)" style="width: 160px"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="TotalPrice" label="合计金额"></el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
      <div slot="footer">
        <el-button size="small" @click="ExamineDialog = false" v-prevent-click>取 消</el-button>
        <el-button type="danger" plain size="small" @click="finalRejectionDialogVisibleClick">审核驳回</el-button>
        <el-button type="primary" size="small" :loading="modalLoading" v-prevent-click @click="submitApproved('pass')">审核通过 </el-button>
      </div>
    </el-dialog>
    <!-- 配送出库 -->
    <el-dialog title="配送出库" :visible.sync="PatchDialog" width="1200px" @close="CloseDialog">
      <div style="height: 60vh">
        <el-scrollbar class="el-scrollbar_height">
          <div class="tip marbm_10" style="margin-top: 0">基本信息</div>
          <el-form ref="purchase" :model="ruleForm" label-width="130px" size="small">
            <el-row class="dis_flex flex_wrap">
              <el-col :span="8">
                <el-form-item label="采购出库仓:" prop="warehouseName">{{ ruleForm.OutboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申请人:" prop="receiving">{{ ruleForm.CreatedByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="Remark" label="申请日期:">{{ ruleForm.CreatedOn }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="采购入库仓:" prop="warehouseName">{{ ruleForm.InboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="审批人:" prop="receiving">{{ ruleForm.ApprovedByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="Remark" label="审批日期:">{{ ruleForm.ApprovedOn }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="Remark" label="配送备注信息:">
                  <el-input size="small" v-model="ruleForm.DisPathRemark" placeholder="请输入备注信息" style="width: 180px"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item>
                  <div slot="label" class="dis_flex flex_wrap">
                    <span class="font_13 color_666 width__fill_available">图片上传:</span>
                    <span class="font_13 color_999 width__fill_available" style="margin-top: -10px">上传配送单据</span>
                  </div>
                  <el-upload
                    class="dis_flex"
                    action="#"
                    list-type="picture-card"
                    :before-upload="
                      (file) => {
                        return beforeAvatarUpload(file, 'delivery');
                      }
                    "
                    :file-list="ruleForm.ImageUrlList"
                  >
                    <!-- :file-list="fileList" -->
                    <i slot="default" class="el-icon-camera-solid" style="font-size: 40px; color: #999"></i>
                    <div style="width: 80px; height: 80px" slot="file" slot-scope="{ file }">
                      <el-image class="el-upload-list__item-thumbnail" style="width: 80px; height: 80px" :src="file.url" :id="file.uid" :preview-src-list="ruleForm.images" :z-index="9999" />
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="tip marbm_10" style="margin-top: 0">产品明细</div>
          <el-table empty-text="暂无产品" size="small" class="martp_10" max-height="280px" :data="ruleForm.Detail">
            <el-table-column type="expand">
              <div slot-scope="scope" v-if="scope.row.type == 'Package'">
                <el-row class="expand-class" v-for="item in scope.row.ProductList" :key="item.ID">
                  <el-col :span="4"
                    >{{ item.Name }}
                    <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                  </el-col>
                  <el-col :span="4">{{ item.StockQuantity }}</el-col>
                  <el-col :span="4">{{ item.Quantity }}</el-col>
                  <el-col v-if="isPriceShow" :span="4">{{ item.Price | NumFormat }}</el-col>
                  <el-col v-else :span="4">***</el-col>
                  <el-col v-if="isPriceShow" :span="4">{{ item.TotalPrice | NumFormat }}</el-col>
                  <el-col v-else :span="4">***</el-col>
                  <el-col :span="3">{{ item.MinimumUnitQuantity + item.MinimumUnitName }}</el-col>
                </el-row>
              </div>
            </el-table-column>
            <el-table-column label="产品名称/套餐名称" prop="Name" width="180">
              <template slot-scope="{ row }">
                {{ row.Name }} <el-tag size="mini" class="marlt_10">{{ row.type == "Package" ? "套餐" : "产品" }}</el-tag>
              </template></el-table-column
            >
            <el-table-column prop="StockQuantity" label="可用库存"></el-table-column>
            <el-table-column prop="Quantity" label="采购数量"></el-table-column>
            <el-table-column prop="Price" label="采购价">
              <template slot-scope="scope">
                <span v-if="isPriceShow">{{ scope.row.Price }}</span>
                <span v-else>***</span>
              </template>
            </el-table-column>
            <el-table-column prop="TotalPrice" label="小计">
              <template slot-scope="scope">
                <span v-if="isPriceShow">{{ scope.row.TotalPrice }}</span>
                <span v-else>***</span>
              </template>
            </el-table-column>
            <el-table-column label="实发最小包装数量">
              <template slot-scope="{ row }">
                <span v-if="row.type == 'Product'">{{ row.MinimumUnitQuantity + row.MinimumUnitName }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
      <div slot="footer">
        <el-button size="small" @click="PatchDialog = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" :loading="modalLoading" @click="submitDispatch" v-prevent-click>配送出库 </el-button>
      </div>
    </el-dialog>
    <!-- 采购入库 -->
    <el-dialog title="采购入库" :visible.sync="PutinDialog" width="1200px" @close="CloseDialog">
      <div style="height: 60vh">
        <el-scrollbar class="el-scrollbar_height">
          <div class="tip marbm_10" style="margin-top: 0">基本信息</div>
          <el-form ref="purchase" :model="ruleForm" label-width="130px" size="small">
            <el-row class="dis_flex flex_wrap">
              <el-col :span="8">
                <el-form-item label="采购入库仓:" prop="InboundEntityName">{{ ruleForm.InboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申请人:" prop="CreatedByName">{{ ruleForm.CreatedByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item v-if="isPriceShow" label="采购总额:"> {{ ruleForm.TotalAmount | NumFormat }} </el-form-item>
                <el-form-item v-else label="采购总额:"> ***</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="配送备注信息:" prop="Remark">{{ ruleForm.OutboundRemark }}</el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="图片查看:">
                  <div class="dis_flex flex_wrap">
                    <el-image v-for="item in ruleForm.OutboundImage" :key="item" :src="item" :preview-src-list="ruleForm.OutboundImage" class="image"></el-image>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="tip marbm_10" style="margin-top: 0">产品明细</div>
          <el-table empty-text="暂无产品" size="small" class="martp_10" max-height="280px" :data="ruleForm.Detail" @selection-change="removeHandleChangeSelectProduct">
            <el-table-column type="expand" class="text_right">
              <div slot-scope="scope" v-if="scope.row.type == 'Package'">
                <el-row class="expand-class" v-for="item in scope.row.ProductList" :key="item.ID">
                  <el-col :span="4"
                    >{{ item.Name }}
                    <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                  </el-col>
                  <el-col :span="5">{{ item.Quantity }}</el-col>
                  <el-col v-if="isPriceShow" :span="5">{{ item.Price | NumFormat }}</el-col>
                  <el-col v-else :span="5">***</el-col>
                  <el-col v-if="isPriceShow" :span="5">{{ item.TotalPrice | NumFormat }}</el-col>
                  <el-col v-else :span="5">***</el-col>
                  <el-col :span="5">{{ item.MinimumUnitQuantity + item.MinimumUnitName }}</el-col>
                </el-row>
              </div>
            </el-table-column>
            <el-table-column label="产品名称/套餐名称" prop="ProductName" width="180">
              <template slot-scope="{ row }">
                {{ row.Name }} <el-tag size="mini" class="marlt_10">{{ row.type == "Package" ? "套餐" : "产品" }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="Quantity" label="采购数量"></el-table-column>
            <el-table-column prop="Price" label="采购价(元)">
              <template slot-scope="scope">
                <span v-if="isPriceShow">{{ scope.row.Price }}</span>
                <span v-else>***</span>
              </template>
            </el-table-column>
            <el-table-column prop="TotalPrice" label="小计(元)">
              <template slot-scope="scope">
                <span v-if="isPriceShow">{{ scope.row.TotalPrice }}</span>
                <span v-else>***</span>
              </template>
            </el-table-column>
            <el-table-column label="实收最小包装数量">
              <template slot-scope="{ row }">
                <span v-if="row.type == 'Product'">{{ row.MinimumUnitQuantity + row.MinimumUnitName }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
      <div slot="footer">
        <el-button size="small" @click="PutinDialog = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" :loading="modalLoading" @click="submitPutIn" v-prevent-click>确认入库 </el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog title="采购详情" :visible.sync="DetailDialog" width="1200px" @close="CloseDialog">
      <div style="height: 60vh">
        <el-scrollbar class="el-scrollbar_height">
          <div class="tip marbm_10" style="margin-top: 0">基本信息-{{ formatStatus(ruleForm.BillStatus) }} ({{ ruleForm.ID }})</div>
          <!-- 待审核 -->
          <el-form v-if="ruleForm.BillStatus == '10'" ref="purchase" :model="ruleForm" label-width="130px" size="small">
            <el-row class="dis_flex flex_wrap">
              <el-col :span="8">
                <el-form-item label="采购出库仓:" prop="OutboundEntityName">{{ ruleForm.OutboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申请人:" prop="CreatedByName">{{ ruleForm.CreatedByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="CreatedOn" label="申请日期:">{{ ruleForm.CreatedOn }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="采购入库仓:" prop="InboundEntityName">{{ ruleForm.InboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item v-if="isPriceShow" label="采购总额:" prop="TotalAmount">***</el-form-item>
                <el-form-item v-else label="采购总额:" prop="TotalAmount">{{ ruleForm.TotalAmount | NumFormat }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申请备注信息:" prop="Remark">{{ ruleForm.Remark }}</el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="图片查看:">
                  <div class="dis_flex flex_wrap">
                    <el-image v-for="item in ruleForm.ApplyImage" :key="item" :src="item" :preview-src-list="ruleForm.ApplyImage" class="image"></el-image>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- 待配送 -->
          <el-form v-else-if="ruleForm.BillStatus == '20'" ref="purchase" :model="ruleForm" label-width="130px" size="small">
            <el-row class="dis_flex flex_wrap">
              <el-col :span="8">
                <el-form-item label="采购出库仓:" prop="OutboundEntityName">{{ ruleForm.OutboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申请人:" prop="CreatedByName">{{ ruleForm.CreatedByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="CreatedOn" label="申请日期:">{{ ruleForm.CreatedOn }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="采购入库仓:" prop="InboundEntityName">{{ ruleForm.InboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="审批人:" prop="ApprovedByName">{{ ruleForm.ApprovedByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="审批日期:" prop="ApprovedOn">{{ ruleForm.ApprovedOn }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item v-if="isPriceShow" label="采购总额:" prop="TotalAmount">{{ ruleForm.TotalAmount | NumFormat }}</el-form-item>
                <el-form-item v-else label="采购总额:" prop="TotalAmount">***</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申请备注信息:" prop="Remark">{{ ruleForm.Remark }}</el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="图片查看:">
                  <div class="dis_flex flex_wrap">
                    <el-image v-for="item in ruleForm.ApplyImage" :key="item" :src="item" :preview-src-list="ruleForm.ApplyImage" class="image"></el-image>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- 待入库 -->
          <el-form v-else-if="ruleForm.BillStatus == '30'" ref="purchase" :model="ruleForm" label-width="130px" size="small">
            <el-row class="dis_flex flex_wrap">
              <el-col :span="8">
                <el-form-item label="采购出库仓:" prop="OutboundEntityName">{{ ruleForm.OutboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申请人:" prop="CreatedByName">{{ ruleForm.CreatedByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="CreatedOn" label="申请日期:">{{ ruleForm.CreatedOn }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="采购入库仓:" prop="InboundEntityName">{{ ruleForm.InboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="审批人:" prop="ApprovedByName">{{ ruleForm.ApprovedByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="审批日期:" prop="ApprovedOn">{{ ruleForm.ApprovedOn }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item v-if="isPriceShow" label="采购总额:" prop="TotalAmount">{{ ruleForm.TotalAmount | NumFormat }}</el-form-item>
                <el-form-item v-else label="采购总额:" prop="TotalAmount">***</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="配送出库单号:" prop="OutboundBillID">{{ ruleForm.OutboundBillID }}<el-button type="text" v-if="isViewDeliveryBill" @click="PatchDetailDialog = true">查看</el-button></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申请备注信息:" prop="Remark">{{ ruleForm.Remark }}</el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="图片查看:">
                  <div class="dis_flex flex_wrap">
                    <el-image v-for="item in ruleForm.ApplyImage" :key="item" :src="item" :preview-src-list="ruleForm.ApplyImage" class="image"></el-image>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- 已完成 -->
          <el-form v-else-if="ruleForm.BillStatus == '50'" ref="purchase" :model="ruleForm" label-width="130px" size="small">
            <el-row class="dis_flex flex_wrap">
              <el-col :span="8">
                <el-form-item label="采购出库仓:" prop="OutboundEntityName">{{ ruleForm.OutboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申请人:" prop="CreatedByName">{{ ruleForm.CreatedByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="CreatedOn" label="申请日期:">{{ ruleForm.CreatedOn }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="采购入库仓:" prop="InboundEntityName">{{ ruleForm.InboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="审批人:" prop="ApprovedByName">{{ ruleForm.ApprovedByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="审批日期:" prop="ApprovedOn">{{ ruleForm.ApprovedOn }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item v-if="isPriceShow" label="采购总额:" prop="TotalAmount">{{ ruleForm.TotalAmount | NumFormat }}</el-form-item>
                <el-form-item v-else label="采购总额:" prop="TotalAmount">***</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="配送出库单号:" prop="OutboundBillID">{{ ruleForm.OutboundBillID }} <el-button type="text" v-if="isViewDeliveryBill" @click="PatchDetailDialog = true">查看</el-button> </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="配送入库单号:" prop="InboundBillID">{{ ruleForm.InboundBillID }} <el-button type="text" v-if="isViewStorageyBill" @click="PutInDetailDialog = true">查看</el-button></el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="图片查看:">
                  <div class="dis_flex flex_wrap">
                    <el-image v-for="item in ruleForm.ApplyImage" :key="item" :src="item" :preview-src-list="ruleForm.ApplyImage" class="image"></el-image>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- 已关闭 / 已驳回 -->
          <el-form v-else ref="purchase" :model="ruleForm" label-width="130px" size="small">
            <el-row class="dis_flex flex_wrap">
              <el-col :span="8">
                <el-form-item label="采购入库仓:" prop="InboundEntityName">{{ ruleForm.InboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申请人:" prop="CreatedByName">{{ ruleForm.CreatedByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="CreatedOn" label="申请日期:">{{ ruleForm.CreatedOn }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item v-if="isPriceShow" label="采购总额:" prop="TotalAmount">{{ ruleForm.TotalAmount | NumFormat }}</el-form-item>
                <el-form-item v-else label="采购总额:" prop="TotalAmount">***</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="备注信息:" prop="Remark">{{ ruleForm.Remark }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="审批人:" prop="ApprovedByName">{{ ruleForm.ApprovedByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="审批日期:" prop="ApprovedOn">{{ ruleForm.ApprovedOn }}</el-form-item>
              </el-col>
              <el-col :span="8" v-if="ruleForm.BillStatus == '40'">
                <el-form-item label="驳回原因:" prop="RejectReason">{{ ruleForm.RejectReason }}</el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="图片查看:">
                  <div class="dis_flex flex_wrap">
                    <el-image v-for="item in ruleForm.ApplyImage" :key="item" :src="item" :preview-src-list="ruleForm.ApplyImage" class="image"></el-image>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="tip marbm_10" style="margin-top: 0">产品明细</div>
          <el-table empty-text="暂无产品" size="small" class="martp_10" max-height="280px" :data="ruleForm.Detail" @selection-change="removeHandleChangeSelectProduct">
            <el-table-column type="expand" class="text_right">
              <div slot-scope="scope" v-if="scope.row.type == 'Package'">
                <el-row class="expand-class" v-for="item in scope.row.ProductList" :key="item.ID">
                  <el-col :span="4"
                    >{{ item.Name }}
                    <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                  </el-col>
                  <el-col :span="5">{{ item.Quantity }}</el-col>
                  <el-col v-if="isPriceShow" :span="5">{{ item.Price | NumFormat }}</el-col>
                  <el-col v-else :span="5">***</el-col>
                  <el-col v-if="isPriceShow" :span="5">{{ item.TotalPrice | NumFormat }}</el-col>
                  <el-col v-else :span="5">***</el-col>
                  <el-col :span="5">{{ item.MinimumUnitQuantity + item.MinimumUnitName }}</el-col>
                </el-row>
              </div>
            </el-table-column>
            <el-table-column label="产品名称/套餐名称" prop="Name" width="180">
              <template slot-scope="{ row }">
                {{ row.Name }} <el-tag size="mini" class="marlt_10">{{ row.type == "Package" ? "套餐" : "产品" }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="Quantity" label="采购数量"></el-table-column>
            <el-table-column prop="Price" label="采购价(元)">
              <template slot-scope="scope">
                <span v-if="isPriceShow">{{ scope.row.Price }}</span>
                <span v-else>***</span>
              </template>
            </el-table-column>
            <el-table-column prop="TotalPrice" label="小计(元)">
              <template slot-scope="scope">
                <span v-if="isPriceShow">{{ scope.row.TotalPrice }}</span>
                <span v-else>***</span>
              </template>
            </el-table-column>
            <el-table-column label="实收最小包装数量">
              <template slot-scope="{ row }">
                <span v-if="row.type == 'Product'">{{ row.MinimumUnitQuantity + row.MinimumUnitName }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
    </el-dialog>
    <!-- 配送出库 -->
    <el-dialog title="配送出库详情" :visible.sync="PatchDetailDialog" width="1200px" @close="CloseDialog">
      <div style="height: 60vh">
        <el-scrollbar class="el-scrollbar_height">
          <div class="tip marbm_10" style="margin-top: 0">基本信息</div>
          <el-form ref="purchase" :model="ruleForm" label-width="130px" size="small">
            <el-row class="dis_flex flex_wrap">
              <el-col :span="8">
                <el-form-item label="采购出库仓:" prop="warehouseName">{{ ruleForm.OutboundEntityName }}</el-form-item>
              </el-col>
              <!-- <el-col :span="8">
                <el-form-item label="申请人:" prop="receiving">{{
                  ruleForm.CreatedByName
                }}</el-form-item>
              </el-col> -->
              <!-- <el-col :span="8">
                <el-form-item prop="Remark" label="申请日期:">{{
                  ruleForm.CreatedOn
                }}</el-form-item>
              </el-col> -->
              <!-- <el-col :span="8">
                <el-form-item label="采购入库仓:" prop="warehouseName">{{
                  ruleForm.InboundEntityName
                }}</el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="操作人:" prop="DelivererByName">{{ ruleForm.DelivererByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="DelivererOn" label="出库时间:">{{ ruleForm.DelivererOn }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="Remark" label="配送备注信息:">
                  {{ ruleForm.OutboundRemark }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="图片查看:">
                  <div class="dis_flex flex_wrap">
                    <el-image v-for="item in ruleForm.OutboundImage" :key="item" :src="item" :preview-src-list="ruleForm.OutboundImage" class="image"></el-image>
                  </div>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="16">
                <el-form-item>
                  <div slot="label" class="dis_flex flex_wrap">
                    <span class="font_13 color_666 width__fill_available"
                      >图片上传:</span
                    >
                    <span
                      class="font_13 color_999 width__fill_available"
                      style="margin-top: -10px"
                      >上传配送单据</span
                    >
                  </div>
                  <el-upload
                    class="dis_flex"
                    action="#"
                    list-type="picture-card"
                    :before-upload="
                      (file) => {
                        return beforeAvatarUpload(file,'delivery');
                      }
                    "
                    :file-list="ruleForm.ImageUrlList"
                  >
                    <i
                      slot="default"
                      class="el-icon-camera-solid"
                      style="font-size: 40px; color: #999"
                    ></i>
                    <div
                      style="width: 80px; height: 80px"
                      slot="file"
                      slot-scope="{ file }"
                    >
                      <el-image
                        class="el-upload-list__item-thumbnail"
                        style="width: 80px; height: 80px"
                        :src="file.url"
                        :id="file.uid"
                        :preview-src-list="ruleForm.images"
                        :z-index="9999"
                      />
                      <span class="el-upload-list__item-actions">
                        <span
                          class="el-upload-list__item-preview"
                          @click="handlePictureCardPreview(file)"
                        >
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <span
                          class="el-upload-list__item-delete"
                          @click="handleRemove(file)"
                        >
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col> -->
            </el-row>
          </el-form>
          <div class="tip marbm_10" style="margin-top: 0">产品明细</div>
          <el-table empty-text="暂无产品" size="small" class="martp_10" max-height="280px" :data="ruleForm.Detail">
            <el-table-column type="expand">
              <div slot-scope="scope" v-if="scope.row.type == 'Package'">
                <el-row class="expand-class" v-for="item in scope.row.ProductList" :key="item.ID">
                  <el-col :span="4"
                    >{{ item.Name }}
                    <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                  </el-col>
                  <el-col :span="5">{{ item.StockQuantity }}</el-col>
                  <el-col :span="5">{{ item.Quantity }}</el-col>
                  <el-col :span="5">{{ item.Price | NumFormat }}</el-col>
                  <el-col :span="5">{{ item.MinimumUnitQuantity + item.MinimumUnitName }}</el-col>
                </el-row>
              </div>
            </el-table-column>
            <el-table-column label="产品名称/套餐名称" prop="Name" width="180">
              <template slot-scope="{ row }">
                {{ row.Name }} <el-tag size="mini" class="marlt_10">{{ row.type == "Package" ? "套餐" : "产品" }}</el-tag>
              </template></el-table-column
            >
            <el-table-column prop="StockQuantity" label="可用库存"></el-table-column>
            <el-table-column prop="Quantity" label="采购数量"></el-table-column>
            <el-table-column prop="Price" label="采购价"> </el-table-column>
            <el-table-column label="实发最小包装数量">
              <template slot-scope="{ row }">
                <span v-if="row.type == 'Product'">{{ row.MinimumUnitQuantity + row.MinimumUnitName }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
    </el-dialog>
    <!-- 采购入库 -->
    <el-dialog title="配送入库详情" :visible.sync="PutInDetailDialog" width="1200px" @close="CloseDialog">
      <div style="height: 60vh">
        <el-scrollbar class="el-scrollbar_height">
          <div class="tip marbm_10" style="margin-top: 0">基本信息</div>
          <el-form ref="purchase" :model="ruleForm" label-width="130px" size="small">
            <el-row class="dis_flex flex_wrap">
              <!-- <el-col :span="8">
                <el-form-item label="采购出库仓:" prop="warehouseName">{{
                  ruleForm.OutboundEntityName
                }}</el-form-item>
              </el-col> -->
              <!-- <el-col :span="8">
                <el-form-item label="申请人:" prop="receiving">{{
                  ruleForm.CreatedByName
                }}</el-form-item>
              </el-col> -->
              <!-- <el-col :span="8">
                <el-form-item prop="Remark" label="申请日期:">{{
                  ruleForm.CreatedOn
                }}</el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="采购入库仓:" prop="warehouseName">{{ ruleForm.InboundEntityName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="操作人:" prop="receiving">{{ ruleForm.ApprovedByName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="InboundOn" label="入库时间:">{{ ruleForm.InboundOn }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="Remark" label="配送备注信息:">
                  {{ ruleForm.DisPathRemark }}
                </el-form-item>
              </el-col>
              <!-- <el-col :span="16">
                <el-form-item>
                  <div slot="label" class="dis_flex flex_wrap">
                    <span class="font_13 color_666 width__fill_available"
                      >图片上传:</span
                    >
                    <span
                      class="font_13 color_999 width__fill_available"
                      style="margin-top: -10px"
                      >上传配送单据</span
                    >
                  </div>
                  <el-upload
                    class="dis_flex"
                    action="#"
                    list-type="picture-card"
                    :before-upload="
                      (file) => {
                        return beforeAvatarUpload(file,'delivery');
                      }
                    "
                    :file-list="ruleForm.ImageUrlList"
                  >
                    <i
                      slot="default"
                      class="el-icon-camera-solid"
                      style="font-size: 40px; color: #999"
                    ></i>
                    <div
                      style="width: 80px; height: 80px"
                      slot="file"
                      slot-scope="{ file }"
                    >
                      <el-image
                        class="el-upload-list__item-thumbnail"
                        style="width: 80px; height: 80px"
                        :src="file.url"
                        :id="file.uid"
                        :preview-src-list="ruleForm.images"
                        :z-index="9999"
                      />
                      <span class="el-upload-list__item-actions">
                        <span
                          class="el-upload-list__item-preview"
                          @click="handlePictureCardPreview(file)"
                        >
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <span
                          class="el-upload-list__item-delete"
                          @click="handleRemove(file)"
                        >
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col> -->
            </el-row>
          </el-form>
          <div class="tip marbm_10" style="margin-top: 0">产品明细</div>
          <el-table empty-text="暂无产品" size="small" class="martp_10" max-height="280px" :data="ruleForm.Detail">
            <el-table-column type="expand">
              <div slot-scope="scope" v-if="scope.row.type == 'Package'">
                <el-row class="expand-class" v-for="item in scope.row.ProductList" :key="item.ID">
                  <el-col :span="4"
                    >{{ item.Name }}
                    <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                  </el-col>
                  <el-col :span="5">{{ item.StockQuantity }}</el-col>
                  <el-col :span="5">{{ item.Quantity }}</el-col>
                  <el-col :span="5">{{ item.Price | NumFormat }}</el-col>
                  <el-col :span="5">{{ item.MinimumUnitQuantity + item.MinimumUnitName }}</el-col>
                </el-row>
              </div>
            </el-table-column>
            <el-table-column label="产品名称/套餐名称" prop="Name" width="180">
              <template slot-scope="{ row }">
                {{ row.Name }} <el-tag size="mini" class="marlt_10">{{ row.type == "Package" ? "套餐" : "产品" }}</el-tag>
              </template></el-table-column
            >
            <el-table-column prop="StockQuantity" label="可用库存"></el-table-column>
            <el-table-column prop="Quantity" label="采购数量"></el-table-column>
            <el-table-column prop="Price" label="采购价"> </el-table-column>
            <el-table-column label="实发最小包装数量">
              <template slot-scope="{ row }">
                <span v-if="row.type == 'Product'">{{ row.MinimumUnitQuantity + row.MinimumUnitName }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
    </el-dialog>
    <!-- 驳回审核 -->
    <el-dialog width="600px" :visible.sync="finalRejectionDialogVisible" title="审核驳回">
      <el-input type="textarea" :rows="4" v-model="finalRejection" placeholder="请输入备注内容"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="finalRejectionDialogVisible = false" size="small">取消</el-button>
        <el-button type="danger" @click="submitApproved('fail')" :loading="modalLoading" size="small">驳回审核</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/PSI/Purchase/allianceBusiness";
import permission from "@/components/js/permission.js";
var Enumerable = require("linq");
export default {
  name: "allianceBusiness",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      /**  新建  */
      vm.isAdd = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-Add");
      /**  审核  */
      vm.isCheck = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-Check");
      /** 关闭待审核 */
      vm.isClose = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-Close");
      /** 关闭已驳回 */
      vm.isTurnClose = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-RejectClose");
      /**  配送 */
      vm.isDelivery = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-Delivery");
      /**  入库 */
      vm.isStorage = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-Storage");
      /**  查看配送出库单 */
      vm.isViewDeliveryBill = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-ViewDeliveryBill");
      /**  查看采购入库单  */
      vm.isViewStorageyBill = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-ViewStorageyBill");
      /**  查看价格权限**/
      vm.isPriceShow = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-AmountHide");
    });
  },
  data() {
    return {
      downloadExcel1: false,
      isPriceShow: false, // 价格权限
      isAdd: false, //新建
      isCheck: false, //审核
      isDelivery: false, // 配送
      isClose: false, //关闭
      isTurnClose: false, //关闭驳回
      isStorage: false, // 入库
      isViewDeliveryBill: false, //查看配送单
      isViewStorageyBill: false, //查看入库单

      StatusNumberInfo: {}, //状态
      DetailDialog: false, //详情弹窗
      PatchDetailDialog: false, //配送出库详情
      PutInDetailDialog: false, //入库详情
      loading: false, //页面加载蒙层
      Dialog: false, //新增弹窗
      ExamineDialog: false, //审批弹窗
      PatchDialog: false, // 配送弹窗
      PutinDialog: false, //采购入库
      modalLoading: false, //提交按钮状态
      removeDisabled: true, //产出按钮
      finalRejectionDialogVisible: false, //驳回弹窗
      finalRejection: "", //驳回理由
      procurement: "",
      warehousing: "",
      OrderBillN: "",
      TimeArray: [],
      ActiveName: "333",
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 1, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      warehouseList: [], //发货仓
      entityList: [], //收货仓
      tableData: [],
      ruleForm: {
        InboundEntityID: "", //收货仓ID
        OutboundEntityID: "", //发货仓ID
        warehouseName: "", //发货仓
        deliveryName: "", //收货仓sssss
        imageList: [], //新建申请上传图片列表
        ImageUrlList: [], // 配送图片列表
        images: [], //预览图片列表
        Detail: [], //产品
        PackDetail: [], //套餐卡
        Remark: "", //备注
        TotalAmount: 0, //套餐总价
      },
      ProductList: [], //产品
      ProducTotal: 0, //产品总条数
      productPageNum: 1,
      Packagecard: [], //套餐卡
      PackageTotal: 0,
      PackagePageNum: 1,
      Units: [], //单位
      purchaseRules: {
        InboundEntityID: [
          {
            required: true,
            message: "请选择收货仓",
            trigger: ["blur", "change"],
          },
        ],
        OutboundEntityID: [
          {
            required: true,
            message: "请选择发货仓",
            trigger: ["blur", "change"],
          },
        ],
      },
      proportion: 0, // 比例
    };
  },
  methods: {
    formatStatus(key) {
      var status = "";
      switch (Number(key)) {
        case 10:
          status = "待审核";
          break;
        case 20:
          status = "待配送";
          break;
        case 30:
          status = "待入库";
          break;
        case 40:
          status = "已驳回";
          break;
        case 50:
          status = "已完成";
          break;
        default:
          status = "已取消";
          break;
      }
      return status;
    },
    //采购列表搜索
    handleSearch() {
      this.paginations.page = 1;
      this.getList();
    },
    CurrenthandleSearch(page) {
      this.paginations.page = page;
      this.getList();
    },
    //新增采购
    addClick() {
      (this.ruleForm = {
        InboundEntityID: "",
        OutboundEntityID: "",
        warehouseName: "",
        deliveryName: "",
        imageList: [],
        images: [],
        Detail: [],
        PackDetail: [],
        Remark: "",
        TotalAmount: 0,
      }),
        (this.Dialog = true);
    },
    // 新增弹窗关闭回调
    CloseDialog() {},
    //上传图片钩子函数
    beforeAvatarUpload(file, type) {
      let that = this;
      let isFileType = false;
      if (file.type === "image/jpg" || file.type === "image/png" || file.type === "image/jpeg") {
        isFileType = true;
      }
      //   const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isFileType) {
        this.$message.error("只支持图片格式");
        return false;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(evt) {
        let base64 = evt.target.result;
        that.updateCustomerUploadImage(base64, type);
      };
      return false;
    },
    //上传图片
    updateCustomerUploadImage(base64, type) {
      let that = this;
      // API.updateImage({ImageUrl:base64}).then(res=>{
      //   if (res.StateCode == 200) {
      //     console.log(res);
      //     this.ruleForm.imageList.push({
      //   url: res.Data.ImageUrl,
      // });
      // this.ruleForm.images.push(res.Data.ImageUrl);
      //   }else{
      //     this.$message(res.Message)
      //   }
      // })
      if (type == "creat") {
        this.ruleForm.imageList.push({
          url: base64,
        });
      } else {
        this.ruleForm.ImageUrlList.push({
          url: base64,
        });
      }

      this.ruleForm.images.push(base64);
    },
    //预览图片
    handlePictureCardPreview(file) {
      document.getElementById(file.uid).click();
    },
    // 删除图片
    handleRemove(file) {
      var that = this;
      let slef = that.ruleForm;
      let deleIndex = slef.imageList.findIndex((image) => {
        return image.uid == file.uid;
      });
      slef.imageList.splice(deleIndex, 1);
      slef.images.splice(deleIndex, 1);
      //   this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {

      // }).catch();
    },
    /** 远程搜索产品   */
    searchProductListMethod(query) {
      var that = this;
      that.productPageNum = 1;
      that.ProductList = [];
      that.getStorageListNetwork(query);
    },
    /** 远程搜索套餐卡   */
    searchPackageListMethod(query) {
      var that = this;
      that.PackagePageNum = 1;
      that.Packagecard = [];
      that.getStorageList(query);
    },
    /**  加载更多产品  */
    loadMoreProduct() {
      var that = this;
      if (that.ProducTotal > that.ProductList.length) {
        that.productPageNum++;
        that.getStorageListNetwork("");
      }
    },
    /**  加载更多套餐  */
    loadMorePackage() {
      var that = this;
      if (that.PackageTotal > that.Packagecard.length) {
        that.PackagePageNum++;
        that.getStorageList("");
      }
    },
    /**  4.6.采购产品列表 */
    getStorageListNetwork: function(query) {
      var that = this;
      if (this.ruleForm.OutboundEntityID == "") {
        this.$message.error("请选择出库仓库");
        return;
      }
      var params = {
        EntityID: this.ruleForm.InboundEntityID,
        PageNum: that.productPageNum,
        Name: query,
        CollectEntityID: this.ruleForm.InboundEntityID,
      };
      API.productListAll(params).then((res) => {
        if (res.StateCode == 200) {
          that.ProducTotal = res.Total;
          that.ProductList.push.apply(that.ProductList, res.List);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    /**  4.6.采购套餐列表 */
    getStorageList: function(query) {
      var that = this;
      var params = {
        PageNum: that.PackagePageNum,
        ProductName: query,
        EntityID: this.ruleForm.InboundEntityID,
      };
      API.packageListAll(params).then((res) => {
        if (res.StateCode == 200) {
          that.PackageTotal = res.Total;
          that.Packagecard.push.apply(that.Packagecard, res.List);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //添加产品
    addProducts(type) {
      let that = this;
      // that.searchProductName = "";
      that.ruleForm.Detail.push({
        PackageCard: false,
        ProductID: "", // ID
        ProductName: "", // 名称
        Alias: "",
        Quantity: "",
        PCategoryName: "",
        Specification: "", // 规格
        Unit: "",
        UnitID: "",
        UnitAmount: "",
        UnitName: "",
        IsMinimumUnit: "",
        IsDefautSendReceive: "",
        Price: "", //采购价
        Amount: "", //数量
        ProductList: null,
        type,
      });
      this.computeProducts();
    },
    /**  下拉选择产品  */
    handleSelectProduct(itemID, row) {
      var that = this;
      row.UnitID = "";
      row.MinimumUnitQuantity = "";
      row.MinimumUnitID = "";
      row.MinimumUnitName = "";
      row.Specification = "";
      row.Quantity = "";
      row.Price = "";
      row.PackagePrice = "";
      row.ProductID = "";
      row.Amount = "";
      row.Units = [];
      var item = Enumerable.from(that.ProductList)
        .where(function(p) {
          return itemID == p.ID;
        })
        .toArray()[0];
      this.computeProducts();
      if (!item) return;
      console.log(item);
      row.Quantity = item.Quantity;
      row.ProductID = itemID;
      row.Units = item.Unit;
      row.Specification = item.Specification;
      row.Price = item.Price;
      row.MinimumUnitName = item.MinimumUnitName;
      row.MinimumUnitID = item.MinimumUnitID;
      row.UnitID = item.Unit[0].UnitID;
      this.handleSelectUnit(item.Unit[0].UnitID, row);
    },
    // 下拉选择单位
    handleSelectUnit(ID, row) {
      var unit = Enumerable.from(row.Units)
        .where(function(p) {
          return ID == p.UnitID;
        })
        .toArray()[0];
      row.UnitName = unit.UnitName;
      row.UnitAmount = unit.Amount;
      this.computeProducts();
    },
    // 下拉选择套餐卡
    handleSelectPackage(ID, row) {
      row.Price = "";
      row.ProductList = "";
      var item = Enumerable.from(this.Packagecard)
        .where(function(p) {
          return ID == p.ID;
        })
        .toArray()[0];
      this.computeProducts();
      if (!item) return;
      row.Price = item.TotalPrice;
      row.ProductList = item.ProductList;
    },
    removeMultipleProduct() {
      var that = this;
      if (that.multipleProducts.length > 0) {
        for (var i = 0; i < that.ruleForm.Detail.length; i++) {
          that.multipleProducts.forEach(function(item) {
            if (that.ruleForm.Detail[i] == item) {
              that.ruleForm.Detail.splice(i, 1);
            }
          });
        }
      }
      this.computeProducts();
    },
    computeProducts() {
      console.log(this.ruleForm);
      this.ruleForm.TotalAmount = this.ruleForm.Detail.reduce((item, item1) => {
        return item + item1.Price * item1.Amount * (item1.UnitAmount || 1);
      }, 0);
      this.$forceUpdate();
    },
    //提交申请
    submitAdd(ref) {
      this.$refs[ref].validate((validate) => {
        if (validate) {
          this.modalLoading = true;
          var params = this.ruleForm;
          params.ProductAddForms = Enumerable.from(params.Detail)
            .where(function(p) {
              return p.type == "Product";
            })
            .toArray()
            .map((item) => {
              return {
                ProductID: item.ProductID,
                UnitID: item.UnitID,
                MinimumUnitID: item.MinimumUnitID,
                Quantity: item.Amount,
                Price: item.Price,
                TotalAmount: item.Amount * item.Price * item.UnitAmount,
                MinimumUnitQuantity: item.UnitAmount * item.Amount,
              };
            });
          params.PackageAddForms = Enumerable.from(params.Detail)
            .where(function(p) {
              return p.type == "Package";
            })
            .toArray()
            .map((item) => {
              item.ProductList.forEach((p) => {
                p.ProductID = p.ID;
                p.TotalAmount = p.TotalPrice;
                p.MinimumUnitQuantity = p.MinimumUnitAmount;
              });
              return {
                PackageID: item.ID,
                Quantity: item.Amount,
                Price: item.Price,
                TotalAmount: item.Amount * item.Price,
                ProductAddForms: item.ProductList,
              };
            });
          params.ImageUrlList = params.images;
          API.create(params)
            .then((res) => {
              this.modalLoading = false;
              if (res.StateCode == 200) {
                this.$message.success("申请成功");
                this.Dialog = false;
                this.getList();
              } else {
                this.$message.error(res.Message);
              }
            })
            .finally(() => {
              this.modalLoading = false;
            });
        }
      });
    },
    removeHandleChangeSelectProduct(selection) {
      this.multipleProducts = selection;
      if (this.multipleProducts.length > 0) {
        this.removeDisabled = false;
      } else {
        this.removeDisabled = true;
      }
    },
    //审批弹窗
    Approve(row, type) {
      API.info({ ID: row.ID }).then((res) => {
        if (res.StateCode == 200) {
          res.Data.Package.forEach((item) => {
            item.type = "Package";
            item.ProductList = item.Product;
          });
          res.Data.Product.forEach((item) => {
            item.type = "Product";
          });
          res.Data.Detail = [...res.Data.Package, ...res.Data.Product];
          res.Data.ImageUrlList = [];
          res.Data.images = [];
          this.ruleForm = res.Data;
          this[type] = true;
          this.ruleForm.Detail.forEach((i) => {
            if (i.Product) {
              i.Product.forEach((j) => {
                console.log(i);
                j.QuantityTwo = j.Quantity;
                j.Quantity = i.Quantity + " * " + j.Quantity;
                j.TotalPrice = (Number(i.Quantity) * Number(j.TotalPrice)).toFixed(2);
                j.MinimumUnitQuantity = Number(i.Quantity) * Number(j.MinimumUnitQuantity);
              });
            }
          });
        } else {
          this.$message.error(res.Message);
        }
      });
    },
    // 审批/驳回
    submitApproved(type) {
      var ProductAdd = [];
      var PackageAdd = [];
      console.log(this.ruleForm.Detail);
      this.ruleForm.Detail.forEach((item) => {
        if (item.type !== "Package") {
          var obj = new Object();
          obj.ID = item.ID;
          obj.Price = item.Price;
          obj.TotalAmount = item.TotalPrice;
          ProductAdd.push(obj);
        } else {
          var PageObj = new Object();
          PageObj.ID = item.ID;
          PageObj.Price = item.Price;
          PageObj.TotalAmount = item.TotalPrice;
          var ProductAddForms = [];
          item.Product.forEach((ProductItem) => {
            var ProductAddObj = new Object();
            ProductAddObj.ID = ProductItem.ID;
            ProductAddObj.Price = ProductItem.Price;
            ProductAddObj.TotalAmount = ProductItem.TotalPrice;
            ProductAddForms.push(ProductAddObj);
            PageObj.ProductAddForms = ProductAddForms;
          });
          PackageAdd.push(PageObj);
        }
      });
      var params = {
        ID: this.ruleForm.ID,
        BillStatus: type == "pass" ? "20" : "40",
        RejectReason: this.finalRejection,
        ProductAddForms: ProductAdd,
        PackageAddForms: PackageAdd,
      };

      if (type == "fail" && this.finalRejection == "") {
        this.$message.warning("请填写驳回理由");
        return;
      }
      this.modalLoading = true;
      API.approved(params).then((res) => {
        this.modalLoading = false;
        if (res.StateCode == 200) {
          this.$message.success(type == "pass" ? "已审批" : "已驳回");
          this.ExamineDialog = false;
          this.finalRejectionDialogVisible = false;
          this.handleSearch();
        } else {
          this.$message.error(res.Message);
        }
      });
    },
    //配送
    submitDispatch() {
      var params = {
        ID: this.ruleForm.ID,
        Remark: this.ruleForm.DisPathRemark,
        ImageUrlList: this.ruleForm.images,
      };
      this.modalLoading = true;
      API.outbound(params)
        .then((res) => {
          this.modalLoading = false;
          if (res.StateCode == 200) {
            this.$message.success("成功");
            this.PatchDialog = false;
            this.handleSearch();
          } else {
            this.$message.error(res.Message);
          }
        })
        .finally(() => {
          this.modalLoading = false;
        });
    },
    //入库
    submitPutIn() {
      var params = {
        ID: this.ruleForm.ID,
        Remark: this.ruleForm.Remark,
      };
      this.modalLoading = true;
      API.inbound(params).then((res) => {
        this.modalLoading = false;
        if (res.StateCode == 200) {
          this.$message.success("成功");
          this.PutinDialog = false;
          this.handleSearch();
        } else {
          this.$message.error(res.Message);
        }
      });
    },
    //查询收货门店
    entity() {
      API.entity().then((res) => {
        if (res.StateCode == 200) {
          this.entityList = res.Data;
        } else {
          this.$message.error(res.Message);
        }
      });
    },
    //查询出库门店
    warehouseEntity() {
      this.ruleForm.Detail = [];
      if (this.ruleForm.InboundEntityID == "") return;
      API.warehouseEntity({ EntityID: this.ruleForm.InboundEntityID }).then((res) => {
        if (res.StateCode == 200) {
          this.warehouseList = res.Data;
        } else {
          this.$message.error(res.Message);
        }
      });
    },
    getList() {
      this.loading = true;
      var params = {
        ID: this.OrderBillN,
        BillStatus: this.ActiveName == "333" ? "" : this.ActiveName,
        StartDate: this.TimeArray[0] || "",
        EndDate: this.TimeArray[1] || "",
        InboundEntityName: this.warehousing,
        OutboundEntityName: this.procurement,
        PageNum: this.paginations.page,
      };

      API.list(params).then((res) => {
        this.loading = false;
        if (res.StateCode == 200) {
          this.tableData = res.List;
          this.paginations.total = res.Total;
        } else {
          this.$message.error(res, Message);
        }
      });
      API.billStatusNumber(Object.assign(params, { BillStatus: "" })).then((res) => {
        if (res.StateCode == 200) {
          this.StatusNumberInfo = res.Data;
        } else {
          this.$message.error(res.Message);
        }
      });
    },
    //取消
    submitCancle(row) {
      var api = row.BillStatus == "40" ? "cancelRejectApply" : "cancel";
      console.log(api);
      this.$confirm("此操作将关闭该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          API[api]({ ID: row.ID }).then((res) => {
            if (res.StateCode == 200) {
              this.$message.success("已取消");
              this.handleSearch();
            } else {
              this.$message.error(res.Message);
            }
          });
        })
        .catch(() => {});
    },
    permission(vm) {
      var to = this.$route;
      /**  新建  */
      vm.isAdd = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-Add");
      /**  审核  */
      vm.isCheck = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-Check");
      /** 关闭待审核 */
      vm.isClose = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-Close");
      /** 关闭已驳回 */
      vm.isTurnClose = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-RejectClose");
      /**  配送 */
      vm.isDelivery = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-Delivery");
      /**  入库 */
      vm.isStorage = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-Storage");
      /**  查看配送出库单 */
      vm.isViewDeliveryBill = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-ViewDeliveryBill");
      /**  查看采购入库单  */
      vm.isViewStorageyBill = permission.permission(to.meta.Permission, "PSI-Purchase-AlianceBusiness-ViewStorageyBill");
    },
    amountChange(row) {
      console.log(row);
      if (Number(row.Amount) < Number(0)) {
        row.Amount = 0;
      }
    },
    // 新增选择仓库
    OutboundEntityChange() {
      this.ruleForm.Detail = [];
    },
    // 审核驳回
    finalRejectionDialogVisibleClick() {
      this.finalRejectionDialogVisible = true;
      this.finalRejection = "";
    },
    // 审批修改采购价
    ExamineInput(row) {
      console.log(row);
      row.TotalPrice = row.Quantity * Number(row.Price);
      if (row.type == "Package") {
        var arr = row.Product.filter((i) => {
          return i.IsLargess == false;
        });
        if (row.Product.length > 0 && row.Product.length > 1) {
          row.Product.forEach((item) => {
            if (!item.IsLargess) {
              var a = Number(row.Price) / Number(arr.length) / Number(item.QuantityTwo);
              item.Price = a.toFixed(2);
              item.TotalPrice = Number(a * item.MinimumUnitQuantity).toFixed(2);
            }
          });
          // var total = 0;
          // row.Product.forEach((item) => {
          //   total += Number(item.MinimumUnitQuantity) * Number(item.Price);
          // });
          // this.proportion = Number(row.TotalPrice) / Number(total);
          // console.log(this.proportion)
          // row.Product.forEach((item) => {
          //   if (!item.IsLargess) {
          //     item.Price = Number(this.proportion * item.Price * item.QuantityTwo).toFixed(2);
          //     item.TotalPrice = Number(item.Price * item.MinimumUnitQuantity).toFixed(2);
          //   }
          // });
          // this.ruleForm.TotalAmount = row.TotalPrice;
        } else {
          row.Product.forEach((item) => {
            if (!item.IsLargess) {
              item.TotalPrice = row.TotalPrice;
              item.Price = Number(item.TotalPrice / item.MinimumUnitQuantity).toFixed(2);
            }
          });
        }
      }
    },

    /** 数据导出 */
    downloadExcel() {
      var that = this;
      let params = {
        ID: this.OrderBillN,
        // BillStatus: this.ActiveName == "333" ? "" : this.ActiveName,
        StartDate: this.TimeArray[0] || "",
        EndDate: this.TimeArray[1] || "",
        InboundEntityName: this.warehousing,
        OutboundEntityName: this.procurement,
        PageNum: this.paginations.page,
      };
      that.downloadExcel1 = true;
      API.franchiseeProcurement_excel(params)
        .then((res) => {
          this.$message.success({
            message: "正在导出",
            duration: "4000",
          });
          const link = document.createElement("a");
          let blob = new Blob([res], { type: "application/octet-stream" });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = "门店采购明细.xlsx"; //下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(function() {
          that.downloadExcel1 = false;
        });
    },
  },
  mounted() {
    this.entity();
    this.getList();
    this.permission(this);
    this.isPriceShow = permission.permission(this.$route.meta.Permission, "PSI-Purchase-AlianceBusiness-AmountHide");
  },
};
</script>

<style lang="scss">
.el-scrollbar_height {
  height: 100%;
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
.el-tabs--border-card {
  box-shadow: unset;
  border-bottom: unset;
  .el-tabs__content {
    padding: unset;
  }
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  overflow: unset;
  position: relative;
  border: unset;
}
.el-upload--picture-card {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.marlt_10 {
  margin-left: 10px !important;
}
.image {
  width: 80px;
  height: 80px;
  margin: 10px 10px 0 0;
}
.el-table__expanded-cell {
  padding: 0 !important;
  border: unset !important;
}
.expand-class {
  padding-left: 60px;
  height: 49px;
  line-height: 49px;
  border-bottom: 1px solid rgb(235, 238, 245);
  background-color: #f5f5f5;
}
.el-col {
  min-height: 1px !important;
}
</style>
