import route from "../../router";

var Enumerable = require("linq");

export default {
    permission: function (permission, code) {
        var isPermission = Enumerable.from(permission)
            .where(function (i) {
                return i.PermissionCode == code
            })
            .select(val => val.IsPermission)
            .toArray()

        return isPermission[0] || false;
    },
    routerPermission:function(path){
        return route.matcher.match(path).name!="Home";
    },
}