import * as API from "@/api/index";
export default {
  // 产品列表
  productListAll: (params) => {
    return API.POST("api/franchiseeProcurement/productListAll", params);
  },
  // 套餐列表
  packageListAll: (params) => {
    return API.POST("api/franchiseeProcurement/packageListAll", params);
  },
  // 入库仓库
  entity: (params) => {
    return API.POST("api/franchiseeProcurement/entity", params);
  },
  // 出库仓库
  warehouseEntity: (params) => {
    return API.POST("api/franchiseeProcurement/warehouseEntity", params);
  },
  // 新增采购
  create: (params) => {
    return API.POST("api/franchiseeProcurement/create", params);
  },
  // 列表
  list: (params) => {
    return API.POST("api/franchiseeProcurement/list", params);
  },
  // 上传图片
  updateImage: (params) => {
    return API.POST("api/franchiseeProcurement/updateImage", params);
  },
  // 删除图片
  deleteImage: (params) => {
    return API.POST("api/franchiseeProcurement/deleteImage", params);
  },
  // 详情
  info: (params) => {
    return API.POST("api/franchiseeProcurement/info", params);
  },
  // 审核
  approved: (params) => {
    return API.POST("api/franchiseeProcurement/approved", params);
  },
  // 配送
  outbound: (params) => {
    return API.POST("api/franchiseeProcurement/outbound", params);
  },
  // 入库
  inbound: (params) => {
    return API.POST("api/franchiseeProcurement/inbound", params);
  },
  // 取消
  cancel: (params) => {
    return API.POST("api/franchiseeProcurement/cancel", params);
  },
  // 取消驳回
  cancelRejectApply: (params) => {
    return API.POST("api/franchiseeProcurement/cancelRejectApply", params);
  },
  // 列表状态
  billStatusNumber: (params) => {
    return API.POST("api/franchiseeProcurement/billStatusNumber", params);
  },
  // 导出
  franchiseeProcurement_excel: (params) => {
    return API.exportExcel("api/franchiseeProcurement/excel", params);
  },
};
